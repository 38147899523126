<template>
  <v-row justify="center">
    <v-dialog v-model="deleteDialog" max-width="450px">
      <v-card class="pa-3">
        <v-card-title class="text-h5">
          Do you wan't to delete this item?
        </v-card-title>

        <v-card-text>
          It will be permanently removed from your system and cannot be
          recovered.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="passDialogClose()"> Cancel </v-btn>

          <v-btn color="red" elevation="0" dark @click="form.action.delete"
            ><v-icon small left>mdi-delete</v-icon> Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "FormDeleteConfirmModal",
  props: ["deleteDialog", "form"],
  methods: {
    passDialogClose() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>